export const seo = {
  url: 'transport',
  title: {
    pl: 'Transport | Usługi | Rodzaje | Kraje',
    en: 'Transport | Services | Types | Countries',
  },
  desc: {
    pl: 'Poznaj rodzaje transportu, kompleksowe usługi transportowe w różnych branżach. Sprawdź jak wygląda transport w krajach Europy.',
    en: 'Learn about the types of transport, comprehensive transport services in various industries. Check what transport looks like in European countries.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Transport',
    en: 'Transport',
  },
  desc: {
    pl: 'Poznaj rodzaje transportu, kompleksowe usługi transportowe w różnych branżach. Sprawdź jak wygląda transport w krajach Europy.',
    en: 'Learn about the types of transport, comprehensive transport services in various industries. Check what transport looks like in European countries.',
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Transport Omida VLS - polska firma transportowa. Skorzystaj z usług TSL doświadczonego Partnera Logistycznego.',
        en: 'Transport Omida VLS - Polish transport company. Take advantage of TSL services and deliver your transport to the destination.',
      },
      texts: [
        {
          pl: `<span>Omida VLS to polska firma transportowa. Zajmujemy się przewozem ładunków transportem drogowym, kolejowym i intermodalnym. W ramach naszych usług organizujemy multum różnych rodzajów transportu, m.in. <strong>transport całopojazdowy</strong> oraz <strong>transport drobnicowy</strong>.</span>`,
          en: `<span>Omida VLS is a Polish transport company. We deal with the transport of goods by road, rail and intermodal transport. As part of our services, we organize a multitude of different types of transport, incl. <strong>full truckload transport</strong> and <strong> less than truckload transport </strong>.</span>`,
        },
        {
          pl: `<span>Nasze oddziały znajdują się w ponad 30 miejscowościach w całej Polsce. Posiadamy nowoczesne magazyny o powierzchni 200 000 m² w kluczowych lokalizacjach, w Polsce, obsługujące wszystkie procesy logistyczne naszych Klientów.</span>`,
          en: `<span>Our branches are located in over 30 cities all over Poland. We have <a href="https://omida.eu/logistics-centre/">modern wareuhoses</a> with an area of ​​nearly 200,000 m² in key locations in Poland, supporting all logistics processes of our customers.</span>`,
        },
        {
          pl: `<span>Naszą mocną stroną jest transport całopojazdowy <strong>FTL po całej Europie</strong>. To w tym jesteśmy najlepsi!</span>`,
          en: `<span>Our strength is <strong> FTL-full truckload transport across Europe</strong>. This is where we are the best!</span>`,
        },
        {
          pl: `<span>Szukasz transportu towaru w Europie? Wybierz Omida VLS. Stawiamy na <strong>stały kontakt opiekuna z klientem i specjalizację zespołu</strong> w obsłudze danej branży!</span>`,
          en: `<span>Looking to transport goods in Europe? Choose Omida VLS. We focus on <strong> constant contact between the supervisor and the client and the team's specialization </strong> in serving a given industry!</span>`,
        },
      ],
    },
    {
      headline: {
        pl: 'Podział transportu na rodzaje, usługi i branże',
        en: 'spanision of transport into types, services and industries',
      },
      texts: [
        {
          pl: `<span>Transport można podzielić ze względu na typy związane z różnymi usługami. Najbardziej popularnymi rodzajami transportu jest transport FTL oraz LTL. Transport może być również skategoryzowany na podstawie konkretnych <strong>gałęzi przemysłu i produktów</strong>, które są w nich wytwarzane lub branż, w których są one wykorzystywane. Przeczytaj także o <strong>transporcie w różnych państwach</strong>. Skorzystaj z poniższych przycisków, aby przejść do odpowiednich podstron.</span>`,
          en: `<span>Transportation can be spanided into types related to different services. TThe most popular types of transport are FTL and LTL. Transport can also be categorized based on <strong>specific industries and the products</strong> they manufacture or the industries in which they are used. Also read about <strong>transport in different countries</strong>. Use the buttons below to go to the relevant subpages.</span>`,
        },
      ],
    },
  ],
}

export const buttonBack = {
  text: {
    pl: 'Wyceń transport',
    en: 'Quote a transport',
  },
  link: '/wycena-transportu?form=open/',
}
